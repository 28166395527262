<template>
  <div class="weather-stat-view">
    <div class="child-page-title">
      <h3>近5天{{ cityName }}天气预告</h3>
    </div>
    <div class="stat-context">
      <div class="context">
        <div style="min-width: 26%">
          <div>{{ toDayWeather.date }} {{ toDayWeather.week }}</div>
          <img :src="toDayWeather.img">
          <div class="today-tmp-div">
            <div class="today-tmp">
              {{ toDayWeather.tmp }}
            </div>
            <div class="today-tmp-txt">
              <div>℃</div>
              <div>{{ toDayWeather.txt }}(实时)</div>
            </div>
          </div>
          <div>{{ toDayWeather.wind }}级</div>
          <div
            class="today-aqi"
            :style="'background:' + getAqiColor(toDayWeather.aqi)"
          >
            {{ toDayWeather.aqi }} {{ toDayWeather.qlty }}
          </div>
        </div>
        <div v-for="(row, index) in dailyForecast" :key="index">
          <div>{{ row.date }}</div>
          <div>{{ row.week }}</div>
          <img :src="row.img">
          <div>{{ row.tmpMin }} ~ {{ row.tmpMax }}℃</div>
          <div>{{ row.txt }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getWeather } from '@/api/weather'
import { hasVal, getWeek, parseTime } from '@/utils/index'
export default {
  name: 'WeatherView',
  data() {
    return {
      cityName: null,
      dailyForecast: [],
      toDayWeather: {
        date: null,
        week: null,
        txt: null,
        img: null,
        tmp: null,
        wind: null,
        aqi: 0,
        qlty: null
      },
      dataNow: parseTime(new Date().getTime(), '{y}-{m}-{d}'),
      weatherIconMap: {
        100: 'qtImg.png',
        101: 'dyImg.png',
        104: 'rtImg.png',
        302: 'lzyImg.png',
        300: 'lzyImg.png',
        399: 'rainImg.png',
        305: 'rainImg.png',
        306: 'rainImg.png',
        502: 'wuImg.png',
        501: 'wuImg.png'
      }
    }
  },
  mounted() {
    this.removeWather()
  },
  methods: {
    /**
     * 加载项目天气数据
     */
    removeWather() {
      getWeather().then((res) => {
        const { result } = res
        const _heWeather5 = result.HeWeather5
        if (Array.isArray(_heWeather5) && _heWeather5.length > 0) {
          const item = _heWeather5[0] || {}
          this.cityName = item.basic.city
          this.toDayWeather = {
            date: this.dataNow,
            week: getWeek(this.dataNow),
            txt: item.now.cond.txt,
            img: require('../../assets/image/weather/' +
              this.weatherIconMap[item.now.cond.code]),
            tmp: item.now.tmp,
            wind: item.now.wind.dir + '<' + item.now.wind.sc,
            aqi: item.aqi.city.aqi,
            qlty: item.aqi.city.qlty
          }
          if (hasVal(item.daily_forecast)) {
            for (let i = 1; i <= 4; i++) {
              this.dailyForecast.push({
                date: item.daily_forecast[i].date,
                week: getWeek(item.daily_forecast[i].date),
                txt: item.daily_forecast[i].cond.txt_d,
                img: require('../../assets/image/weather/' +
                  this.weatherIconMap[item.daily_forecast[i].cond.code_d]),
                tmpMin: item.daily_forecast[i].tmp.min,
                tmpMax: item.daily_forecast[i].tmp.max
              })
            }
          }
        }
      })
    },
    getAqiColor(aqi) {
      if (aqi <= 50) return '#40c057'
      // 优秀
      else if (aqi <= 100) return '#82c91e'
      // 良好
      else if (aqi <= 150) return '#F76707'
      // 轻度污染
      else if (aqi <= 200) return '#FF5151'
      // 中度污染
      else if (aqi <= 300) return '#CE0000'
      // 重度污染
      else return '#600000' // 严重污染
    }
  }
}
</script>
<style lang='scss' scoped>
.weather-stat-view {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .stat-context {
    margin-top: 15px;
    .context {
      width: 96%;
      box-sizing: border-box;
      border-radius: 5px;
      display: flex;
      margin-top: 10px;
      > div {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        line-height: 28px;
        flex: 1;
        text-align: center;
        border-right: 1px dashed #0e77ca9e;
        > img {
          margin: 8px 0px;
          max-width: 68%;
          height: 48px;
        }
        .today-tmp-div {
          display: flex;
          position: relative;
          margin-top: 10px;
          margin-bottom: 20px;
          margin-left: 20%;
          .today-tmp {
            font-size: 52px;
          }
          .today-tmp-txt {
            text-align: left;
            position: absolute;
            top: -15px;
            left: 40%;
          }
        }
        .today-aqi {
          width: 60%;
          margin: 0 auto;
          margin-top: 10px;
          border-radius: 4px;
        }
      }
    }
  }
}
</style>
